<template>
  <AppSection>
    <AppHeading level="2">API</AppHeading>

    Use MyGeneset programmatically through its flexible API

    <AppFlex>
      <AppButton
        to="/api"
        icon="code"
        text="Try Now"
        fill="filled"
        size="big"
      />
      <AppButton
        to="https://docs.mygeneset.info/en/latest/index.html"
        icon="book"
        text="API Docs"
        fill="filled"
        size="big"
      />
    </AppFlex>
  </AppSection>
</template>
