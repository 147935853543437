<template>
  <AppSection>
    <AppHeading level="2">Get Started</AppHeading>

    Start browsing and building genesets, right on this website

    <AppFlex>
      <AppButton
        to="/browse"
        icon="search"
        text="Browse All"
        fill="filled"
        size="big"
      />
      <AppButton
        to="/build"
        icon="hammer"
        text="Build Your Own"
        fill="filled"
        size="big"
      />
    </AppFlex>
  </AppSection>
</template>
