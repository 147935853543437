<template>
  <AppSection>
    <AppHeading level="2" icon="hammer">Build</AppHeading>

    <!-- start new geneset -->
    <AppButton
      to="/new"
      icon="wand-magic-sparkles"
      text="New Geneset"
      fill="filled"
      size="big"
    />
  </AppSection>
</template>
