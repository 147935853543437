<template>
  <AppSection>
    <AppHeading level="3">Disclaimers</AppHeading>
    <p>
      This resource is intended purely for research purposes. It should not be
      used for emergencies or medical or professional advice.
    </p>
    <p>
      This website is free and open to all users. There is no login requirement
      to access public genesets. Login is only necessary when managing
      user-submitted genesets.
    </p>
  </AppSection>
</template>
