<template>
  <AppSection>
    <AppHeading level="2" link="cite">How to Cite</AppHeading>

    <p class="citation">
      <b
        >High-performance web services for querying gene and variant annotation </b
      ><br />
      Xin J, Mark A, Afrasiabi C, Tsueng G, Juchler M, Gopal N, Stupp GS, Putman
      TE, Ainscough BJ, Griffith OL, Torkamani A, Whetzel PL, Mungall CJ, Mooney
      SD, Su AI, Wu C<br />
      Genome Biology &ndash; 2016
    </p>
  </AppSection>
</template>

<style scoped lang="scss">
.citation {
  padding: 20px;
  border: dashed 2px $gray;
}
</style>
