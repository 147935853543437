<template>
  <AppSection>
    <AppHeading level="2" icon="code">API</AppHeading>

    <!-- action button -->
    <AppFlex width="200px">
      <AppButton
        to="https://docs.mygeneset.info/en/latest/index.html"
        icon="book"
        text="API Docs"
        fill="filled"
        size="big"
      />
      <div>v1.0</div>
    </AppFlex>

    <!-- note -->
    <p>
      Full documentation for using MyGeneset programmatically through its API.
      Detailed info about request parameters, response schemas, and more.
    </p>
  </AppSection>
</template>
