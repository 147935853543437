import { IconDefinition, library } from "@fortawesome/fontawesome-svg-core";
import * as far from "@fortawesome/free-regular-svg-icons";
import * as fas from "@fortawesome/free-solid-svg-icons";
import * as fab from "@fortawesome/free-brands-svg-icons";

// create collection/"palette" of useable icons

library.add(
  ...([
    fab.faGithub,
    fab.faTwitter,
    far.faCircle,
    far.faCircle,
    far.faCircleDot,
    far.faClock,
    far.faComment,
    far.faCopy,
    far.faEnvelope,
    far.faFaceLaughBeam,
    far.faSquare,
    fas.faAddressCard,
    fas.faAngleDoubleDown,
    fas.faAngleDoubleUp,
    fas.faAngleLeft,
    fas.faAngleRight,
    fas.faBars,
    fas.faBars,
    fas.faBook,
    fas.faBook,
    fas.faBox,
    fas.faBriefcase,
    fas.faCheckCircle,
    fas.faCheckSquare,
    fas.faCircleCheck,
    fas.faCode,
    fas.faDatabase,
    fas.faDna,
    fas.faDownload,
    fas.faExclamationCircle,
    fas.faExclamationTriangle,
    fas.faEye,
    fas.faGlobe,
    fas.faGraduationCap,
    fas.faHammer,
    fas.faKey,
    fas.faLink,
    fas.faMinus,
    fas.faPencil,
    fas.faPersonRunning,
    fas.faPlay,
    fas.faPlus,
    fas.faSchool,
    fas.faSearch,
    fas.faSignature,
    fas.faSignInAlt,
    fas.faSort,
    fas.faSortDown,
    fas.faSortUp,
    fas.faTimes,
    fas.faTrashCan,
    fas.faUpload,
    fas.faUser,
    fas.faUserSecret,
    fas.faWandMagicSparkles,
  ] as Array<IconDefinition>)
);
