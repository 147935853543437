<template>
  <AppSection>
    <AppHeading level="2" icon="signature">About</AppHeading>

    <!-- high-level description of app and api -->
    <p>
      MyGeneset allows scientists to conveniently collect, save, and share sets
      of genes from thousands of different species. You can use it through this
      website or programmatically through the
      <AppLink to="/api">API</AppLink>.
    </p>

    <!-- key, distilled, succinct use-cases of app and api -->
    <AppFlex direction="col" h-align="stretch">
      <p>Use MyGeneset to:</p>
      <ul>
        <li>analyze large genomic data without programming experience</li>
        <li>
          collect genes of interest once and easily access/export as multiple
          formats
        </li>
        <li>store genesets in a central location</li>
        <li>
          develop your own gene-centric applications and offload the search and
          write capabilities
        </li>
        <li>...and much more!</li>
      </ul>
    </AppFlex>
  </AppSection>
</template>
